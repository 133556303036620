@font-face {
  font-family: 'CHANEY';
  src: url('/statics/fonts/chaney-regular-webfont.eot');
  src: url('/statics/fonts/chaney-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/statics/fonts/chaney-regular-webfont.woff2') format('woff2'),
  url('/statics/fonts/chaney-regular-webfont.woff') format('woff'),
  url('/statics/fonts/chaney-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Bariol Serif';
  src: url('/statics/fonts/bariol_serif_regular-webfont.eot');
  src: url('/statics/fonts/bariol_serif_regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/statics/fonts/bariol_serif_regular-webfont.woff2') format('woff2'),
  url('/statics/fonts/bariol_serif_regular-webfont.woff') format('woff'),
  url('/statics/fonts/bariol_serif_regular-webfont.ttf') format('truetype'),
  url('/statics/fonts/bariol_serif_regular-webfont.svg#bariol_serifregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bariol Serif';
  src: url('/statics/fonts/bariol_serif_regular_italic-webfont.eot');
  src: url('/statics/fonts/bariol_serif_regular_italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/statics/fonts/bariol_serif_regular_italic-webfont.woff2') format('woff2'),
  url('/statics/fonts/bariol_serif_regular_italic-webfont.woff') format('woff'),
  url('/statics/fonts/bariol_serif_regular_italic-webfont.ttf') format('truetype'),
  url('/statics/fonts/bariol_serif_regular_italic-webfont.svg#bariol_serifitalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bariol Serif';
  src: url('/statics/fonts/bariol_serif_bold-webfont.eot');
  src: url('/statics/fonts/bariol_serif_bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/statics/fonts/bariol_serif_bold-webfont.woff2') format('woff2'),
  url('/statics/fonts/bariol_serif_bold-webfont.woff') format('woff'),
  url('/statics/fonts/bariol_serif_bold-webfont.ttf') format('truetype'),
  url('/statics/fonts/bariol_serif_bold-webfont.svg#bariol_serifbold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bariol Serif';
  src: url('/statics/fonts/bariol_serif_bold_italic-webfont.eot');
  src: url('/statics/fonts/bariol_serif_bold_italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/statics/fonts/bariol_serif_bold_italic-webfont.woff2') format('woff2'),
  url('/statics/fonts/bariol_serif_bold_italic-webfont.woff') format('woff'),
  url('/statics/fonts/bariol_serif_bold_italic-webfont.ttf') format('truetype'),
  url('/statics/fonts/bariol_serif_bold_italic-webfont.svg#bariol_serifbold_italic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Bariol Serif';
  src: url('/statics/fonts/bariol_serif_light-webfont.eot');
  src: url('/statics/fonts/bariol_serif_light-webfont.eot?#iefix') format('embedded-opentype'),
  url('/statics/fonts/bariol_serif_light-webfont.woff2') format('woff2'),
  url('/statics/fonts/bariol_serif_light-webfont.woff') format('woff'),
  url('/statics/fonts/bariol_serif_light-webfont.ttf') format('truetype'),
  url('/statics/fonts/bariol_serif_light-webfont.svg#bariol_seriflight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bariol Serif';
  src: url('/statics/fonts/bariol_serif_light_italic-webfont.eot');
  src: url('/statics/fonts/bariol_serif_light_italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/statics/fonts/bariol_serif_light_italic-webfont.woff2') format('woff2'),
  url('/statics/fonts/bariol_serif_light_italic-webfont.woff') format('woff'),
  url('/statics/fonts/bariol_serif_light_italic-webfont.ttf') format('truetype'),
  url('/statics/fonts/bariol_serif_light_italic-webfont.svg#bariol_serifregular') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Bariol Serif';
  src: url('/statics/fonts/bariol_serif_thin-webfont.eot');
  src: url('/statics/fonts/bariol_serif_thin-webfont.eot?#iefix') format('embedded-opentype'),
  url('/statics/fonts/bariol_serif_thin-webfont.woff2') format('woff2'),
  url('/statics/fonts/bariol_serif_thin-webfont.woff') format('woff'),
  url('/statics/fonts/bariol_serif_thin-webfont.ttf') format('truetype'),
  url('/statics/fonts/bariol_serif_thin-webfont.svg#bariol_serifregular') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Bariol Serif';
  src: url('/statics/fonts/bariol_serif_thin_italic-webfont.eot');
  src: url('/statics/fonts/bariol_serif_thin_italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/statics/fonts/bariol_serif_thin_italic-webfont.woff2') format('woff2'),
  url('/statics/fonts/bariol_serif_thin_italic-webfont.woff') format('woff'),
  url('/statics/fonts/bariol_serif_thin_italic-webfont.ttf') format('truetype'),
  url('/statics/fonts/bariol_serif_thin_italic-webfont.svg#bariol_serifitalic') format('svg');
  font-weight: 100;
  font-style: italic;
}