@import 'stylekit';
@import '../../../node_modules/@fortawesome/fontawesome-svg-core/styles.css';

// Bootstrap
$border-radius: 0.3rem;
$border-radius-lg: 0.4rem;
$border-radius-sm: 0.25rem;

$font-family-base: $quizado-font-family-base;
$primary: $quizado-primary;
$secondary: $quizado-secondary;

$navbar-color: $quizado-primary;
$navbar-dark-color: $quizado-white;
$dropdown-bg: $quizado-black;
$dropdown-link-hover-bg: $quizado-black;

$primary: $quizado-primary;
$secondary: $quizado-secondary;
$body-color: $quizado-primary;
$body-bg: $quizado-accent1;

$modal-content-color: $quizado-black;
$modal-content-bg: $quizado-accent1;
$modal-backdrop-bg: $quizado-black;
$modal-backdrop-opacity: 0.5;

$card-color: $quizado-black;
//$card-bg: lighten($quizado-black, 5%);
//
$card-cap-color: $quizado-black;
//$card-cap-bg: lighten($quizado-black, 10%);
//
//$pagination-bg: $card-cap-bg;
$pagination-color: $quizado-black;
//$pagination-border-color: $quizado-black;
//
//$pagination-hover-bg: $pagination-bg;
//$pagination-hover-border-color: $pagination-border-color;
//
//$pagination-disabled-bg: $quizado-black;
$pagination-disabled-color: $quizado-black;
//$pagination-disabled-border-color: $quizado-black;
$input-bg: $quizado-white;
$input-group-addon-bg: $input-bg;
$input-border-color: $quizado-white;
$input-group-addon-border-color: $input-border-color;
$input-placeholder-color: rgba($quizado-primary, 0.5);
$input-color: $quizado-primary;
$input-group-addon-color: $input-placeholder-color;
$input-box-shadow: 4px 6px 0 0 rgba($quizado-primary, 0.08);
$enable-shadows: true;
$input-font-size: 1.2rem;
$input-border-radius: 0.8rem;
$border-color: rgba($quizado-black, 0.3);

.btn:disabled.btn-input {
  color: $input-placeholder-color;
}

//$input-padding-y $input-padding-x
$theme-colors: (
        'light': $quizado-primary,
        'dark': $quizado-black,
        'body-light': $quizado-black,
        'accent': $quizado-accent1,
        'accent2': $quizado-accent2,
        'input': $input-bg
);

$breadcrumb-active-color: $quizado-black;
$modal-header-border-width: 0;
@import '../../../node_modules/bootstrap/scss/bootstrap';

html {
  font-family: $font-family-base;
}

.btn:disabled.btn-input {
  opacity: 1;
}

.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em;
}

.text-heading {
  font-family: $quizado-font-family-base-headers;
  letter-spacing: 0;
}

.header-1 {
  font-size: 2.5rem;
}

.text-shadow-accent {
  text-shadow: 2px 2px 0 $quizado-accent1;
}

.text-shadow-primary-opacity {
  text-shadow: 4px 4px 0 rgba($quizado-primary, 0.12);
}

.text-shadow-primary {
  text-shadow: 3px 4px 0 $quizado-primary;
}

.text-shadow-primary-thin {
  text-shadow: 2px 2px 0 $quizado-primary;
}

.text-shadow-primary-on-black {
  text-shadow: -4px 4px 0 rgba($quizado-primary,0.40);
}

.text-shadow-accent2 {
  text-shadow: 2px 3px 0 $quizado-accent2;
}

.text-shadow-none {
  text-shadow: none;
}

.btn.btn-mobile-border {
  @include media-breakpoint-down('md') {
    border: 4px solid white;
    border-radius: 16px;
  }
}

.navbar {
  .nav-link {
    @extend .text-shadow-accent;
  }
  .dropdown-menu {
    padding: 0;
    background: $quizado-accent1;
    border: none;
    :hover {
      background: $quizado-secondary;
    }
    .dropdown-item {
      .nav-link {
        color: $quizado-primary;
      }
    }
  }

  &.navbar-dark {
    background: $quizado-black;
    box-shadow: 0 8px 40px 0 rgba(46,54,203,0.12);
    .nav-link {
      @extend .text-shadow-none;
    }
  }
}