@import 'stylekit';
@import 'bootstrap';
@import 'variables';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../node_modules/slick-carousel/slick/slick';
@import '../../../node_modules/slick-carousel/slick/slick-theme';
@import '../../../node_modules/yet-another-react-lightbox/dist/styles.css';
@import '../../../node_modules/yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css';


html {
  font-size: 12px;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 2 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 14px;
  }
}

#__next,
body,
html {
  height: 100%;
  width: 100%;
}
